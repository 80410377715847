import Head from 'next/head'
import React, { useRef, useState } from "react";
import { Button } from '../components/primitives/buttons'
import { Icon } from '../components/primitives/icons'
import { Logo } from '../components/primitives/logo'
import { H1 } from '../components/primitives/text/h1'
import { H15 } from '../components/primitives/text/h15'
import { H2 } from '../components/primitives/text/h2'
import Styles from '../styles/home.module.css'
import {useRouter} from 'next/router'
import { UserContext } from './_app'
import  { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore,{ EffectFade, Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

SwiperCore.use([Autoplay]);

export default function Index() {

  const router = useRouter();
  const {islogged} = React.useContext(UserContext);

  React.useEffect(()=>{
    if(islogged === true){
      router.push('/feed')
    }
  },[islogged])

  return (
    <div className={Styles.cont}>
        <Head>
          <title>Musicwall</title>
          <meta name="theme-color" content={"#232428" }/>
          <meta name="msapplication-navbutton-color" content="#232428"/>
          <meta name="apple-mobile-web-app-status-bar-style" content="#232428"/>
          <meta name="keywords" content={`Musicwall, music sharing, music, spotify, instagram for music, music discovery,frisson,find new music,music,soundcloud`}/>
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"/>
          <meta property="og:site_name" content="Musicwall"/>
          <meta property="og:url" content="https://musicwall.cc"/>
          <meta property="og:type" content="website" />
          <meta name="twitter:image" content="https://musicwall.cc/IMG/og.png" />
          <meta property="og:image" content="https://musicwall.cc/IMG/og.png" />
          <meta name="twitter:card" content="https://musicwall.cc/IMG/og.png" />
          <link rel="icon" href="/SVG/logo_icon.svg" />

          <meta property="og:title" content={"Musicwall "}/>
          <meta property="twitter:title" content={"Musicwall"}/>
          
          <meta name="title" content={"Musicwall"}/>

          <meta name="description" content={`Discover and share music that sparks joy.`}/>
          <meta name="twitter:description" content={`Discover and share music that sparks joy.`}/>
          <meta name="og:description" content={`Discover and share music that sparks joy.`}/>


        </Head>

      {(islogged == false) && (islogged !== 'unset') && <React.Fragment><section className={Styles.col}>
          <nav className={Styles.nav}>
            <div className={Styles.logocont}>
              <Logo.Expanded.colored size={25}/>
            </div>

            <div className={Styles.navbutcont}>
                <Button.primary  className = {Styles.btnsecondary} onClick ={()=>{location.href = '/account/login'}} title="Login"/>

                <Button.primary onClick ={()=>{location.href = '/account/signup'}} title="Sign up"/>
            </div>
          </nav>
          <div style ={{marginTop:20}}/>
          <div className={Styles.hero}>
            <H1.bold>
              Let your <b style ={{color:'var(--blurple)', fill:'var(--blurple)'}}><Icon.Sparkles style={{height:'25px'}}/> unique <Icon.Sparkles style={{height:'25px'}}/></b> music taste be heard.
            </H1.bold>

            <div style ={{marginTop:20}}/>
            <H15.normal>
            A new & unique way to discover and share the music that sparks joy in your life with
            your friends and a passionate community.
              
                           
            </H15.normal>
            <div style ={{marginTop:20}}/>

            <div className={Styles.herobuttoncont}>
                <Button.primary title="Sign up" onClick ={()=>{location.href = '/account/signup'}} btitle={<span>Let the fun <Icon.Sparkles style={{marginRight:0}}/><b>begin</b> <Icon.Sparkles style={{marginLeft:0}}/></span>}/>
            </div>
          </div>

      </section>

      <section className={Styles.col2 }>
        <div className={Styles.slider}>

        <Swiper
            spaceBetween={30}
            effect={"fade"}
            loop = {true}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 1500,
              disableOnInteraction: true,
            }}
            modules={[EffectFade, Navigation, Pagination]}
            className="mySwiper1"
        >
              

            <SwiperSlide>

          <div className={Styles.slide}>
            <div  className={Styles.slidedescr}>
              <H2.bold>
                Discover new music
              </H2.bold>
              <H2.normal >
                from all around the world
              </H2.normal>
            </div>

            <div className ={Styles.screenshotimgcont}>
              <img  width= {260}
                    height={570}
                    className ={Styles.screenshotimg} src ="IMG/Screenshots/1.png"/>
            </div>
          </div>
          </SwiperSlide>

          <SwiperSlide>

            <div className={Styles.slide}>
              <div  className={Styles.slidedescr}>
                <H2.bold>
                  Add your music
                </H2.bold>
                <H2.normal >
                  to share with the world
                </H2.normal>
              </div>

              <div className ={Styles.screenshotimgcont}>
                <img  width= {260}
                      height={570}
                      className ={Styles.screenshotimg} src ="IMG/Screenshots/2.png"/>

              </div>

              
            </div>
          </SwiperSlide>

          <SwiperSlide>

              <div className={Styles.slide}>
                <div  className={Styles.slidedescr}>
                  <H2.bold>
                    Customize your page
                  </H2.bold>
                  <H2.normal >
                    and give it your personality
                  </H2.normal>
                </div>

                <div className ={Styles.screenshotimgcont}>
                  <img  width= {260}
                        height={570}
                        className ={Styles.screenshotimg} src ="IMG/Screenshots/3.png"/>

                </div>

                
              </div>
            </SwiperSlide>
        </Swiper>

        </div>
      </section></React.Fragment>}
    </div>
  )
}
