import React from 'react';

const H1normal = (props) => {
    return (
        <h1 style={{fontSize: 'var(--h1fontsize)',margin:0,marginBottom:5, color:'var(--prifontcolor)',fontWeight: '400'}}{...props}>
            
        </h1>
    );
}

const H1bold = (props) => {
    return (
        <h1 style={{fontSize: 'var(--h1fontsize)',margin:0,marginBottom:5, color:'var(--prifontcolor)',fontWeight: '600'}}{...props}>
            
        </h1>
    );
}

export const H1={
    normal:H1normal,
    bold:H1bold,

};
