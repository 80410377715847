import React from 'react';

const H15normal = (props) => {
    return (
        <h2 style={{fontSize: 'var(--h15fontsize)',margin:0,marginBottom:5, color:'var(--fontcolor)',fontWeight: '400'}}{...props}>
            
        </h2>
    );
}

const H15bold = (props) => {
    return (
        <h2 style={{fontSize: 'var(--h15fontsize)',margin:0,marginBottom:5, color:'var(--fontcolor)',fontWeight: '600'}}{...props}>
            
        </h2>
    );
}

export const H15={
    normal:H15normal,
    bold:H15bold,

};
